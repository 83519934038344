import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import { graphql } from "gatsby"

const PortfolioPage = ({ data }) => (

  <Layout>
    <SEO
      title={`Portfolio`}
      description={`A short list of the various dashboard and web related things I've built over the years.`}
    />
    <div className={`container mx-auto flex-grow h-full`}>
      <div className={`grid grid--250 grid-gap-15`}>
        {data.allMarkdownRemark.edges.map(({ node }, index) => (
          <a className={`block flex flex-col m-6 shadow bg-white overflow-hidden`}
            href={`/${node.frontmatter.slug}`}>
            <div className={`h-56`}>
              <Image className={`w-full h-full`} filename={node.frontmatter.logo} alt={`${node.frontmatter.name} Logo`} />
            </div>
            <div className={`w-full p-6 mx-auto text-center align-middle my-auto`}>
              <h1>{node.frontmatter.name}</h1>
              <div className={`text-gray-600 italic`}>
                {node.frontmatter.tags.map( tag  => (
                  <span className={`p-1 italics text-sm`}>{tag} </span>
                ))}
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  </Layout>
)

export default PortfolioPage

export const query = graphql`
query MyPortfolio {
  allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/src/portfolio/.*.md$/"}},
    sort: {fields: frontmatter___order, order: ASC}
  ) {
    edges {
      node {
        frontmatter {
          name
          slug
          logo
          launched
          tags
        }
        html
      }
    }
  }
}
`